<template>
  <div>
    <PageHeader title="Contactgroepen" />
    <div class="tw-px-2.5 tw-pt-5 tw-pb-16 tw-mx-auto">
      <EditModal :config="activeEditConfig" :object="activeObject" @save="handleGroup" ref="editModal"/>
      <FormulateForm
        #default="{ isLoading }"
        v-model="query"
        name="searchGroups"
        class="tw-mb-8 tw-p-6 tw-bg-white tw-rounded tw-shadow-lg"
        @submit="searchGroups"
      >
        <div class="tw-grid md:tw-grid-cols-3 tw-gap-4">
          <FormulateInput
            type="text"
            name="name"
            label="Naam"
            placeholder="Naam van de groep"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="autocomplete"
            auto-complete-type="office"
            name="having_office"
            label="Kantoren"
            placeholder="Selecteer een kantoor"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="autocomplete"
            auto-complete-type="collaborator"
            name="having_collaborator"
            label="Medewerker"
            placeholder="Selecteer een medewerker"
            outer-class="tw-m-0"
          />
        </div>
        <FormulateErrors />
        <FormulateInput
          type="submit"
          :disabled="isLoading"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-search'
            ]"
          />
          Zoeken
        </FormulateInput>
      </FormulateForm>
      <DataTable
        :loading="loading"
        :headers="headers"
        v-bind="groupData"
        @changedOrder="fetchGroups"
        @changePage="fetchGroups"
      >
        <template #toolbar>
          <div class="tw-mb-2 tw-flex tw-flex-row tw-items-center tw-justify-between">
            <button
              type="button"
              class="action tw-bg-primary"
              @click="showEditModal('group-new', {})"
            >
              <i class="fa fa-plus"/>
              Nieuw
            </button>
            <FormulateInput
              v-if="isStaff"
              v-model="adminView"
              type="toggle"
              name="admin"
              label="Beheerder"
              outer-class="tw-m-0"
            />
          </div>
        </template>
        <template #item-name="{ item }">
          <router-link
            :to="{ name: 'ContactList', query: { group: item.id } }"
          >
            {{ item.name }}
          </router-link>
        </template>
        <template #item-parent="{ item }">
          <router-link
            v-if="item.parent && item.parent.id"
            :to="{ name: 'ContactList', query: { group: item.parent.id } }"
          >
            {{ item.parent.name }}
          </router-link>
          <span v-else>-</span>
        </template>
        <template #item-offices="{ item }">
          <div v-if="item.offices.length">
            <a
              v-for="(office, index) in item.offices"
              :key="index"
              :href="'/organization/office/' + office.id"
            >
              {{ office.reference }}
            </a>
          </div>
          <span v-else>-</span>
        </template>
        <template #item-is_public="{ item }">
          <span class="tw-text-base">
            <i v-if="item.is_public" class="fa fa-check tw-text-primary"/>
            <i v-else class="fa fa-times tw-text-error"/>
          </span>
        </template>
        <template #item-actions="{ item }">
          <div class="tw-flex">
            <template v-if="item.owner === collaborator.id || isStaff">
              <button
                type="button"
                class="action tw-bg-primary"
                @click="showEditModal('group-edit', item)"
              >
                <i class="far fa-pencil" />
              </button>
              <button
                type="button"
                class="action tw-bg-error tw-order-4"
                @click="handleGroupDelete(item.id)"
              >
                <i class="far fa-trash-alt" />
              </button>
            </template>
            <button
              type="button"
              title="Tag toewijzen"
              class="action tw-bg-success"
              @click="showAssignTagModal(item)"
            >
              <i class="far fa-tag" />
            </button>
            <button
              v-if="collaborator.hasPerm('EXPORT_CONTACT_GROUP')"
              type="button"
              class="action tw-bg-info"
              @click="handleGroupExport(item.id)"
            >
              <i class="far fa-file-export" />
            </button>
          </div>
        </template>
      </DataTable>
    </div>
    <AssignTagModal ref="assignTagModal" :group="selectedGroup" @success="handleGroupDelete($event.group.id)" />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import DataTable from '@/components/DataTable'
import EditModal from '@/components/iam/EditModal'

import AssignTagModal from '@/components/contacts/AssignTagModal'

import { mapState, mapGetters, mapActions } from 'vuex'
import {
  getContactGroups,
  createContactsGroup,
  updateContactsGroup,
  deleteContactsGroup,
  exportContactGroup,
  getContactsForGroup
} from '@/services/contacts'
import { errorModal, successModal, questionModal, startLoadingModal } from '@/modalMessages'
import { contactsGroupForms } from '@/forms/contactsGroupForms'

export default {
  name: 'ContactGroups',
  components: {
    PageHeader,
    DataTable,
    EditModal,
    AssignTagModal
  },
  data () {
    return {
      loading: false,
      adminView: false,
      query: {},
      // The groupData includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      groupData: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      activeEditConfig: {},
      activeObject: {},
      selectedGroup: null
    }
  },
  watch: {
    adminView: {
      handler () {
        this.searchGroups(this.query)
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapGetters('account', ['collaborator']),

    isStaff () {
      return this.user.is_staff
    },
    headers () {
      return [
        { text: 'Naam', value: 'name', orderable: true },
        { text: 'Eigenaar', value: 'owner_display' },
        { text: 'Deel van', value: 'parent' },
        { text: 'Kantoren', value: 'offices' },
        { text: 'Publiek', value: 'is_public' },
        { text: 'Acties', value: 'actions' }
      ]
    }
  },
  methods: {
    ...mapActions('contacts', ['pollContactExportStatus']),

    async showAssignTagModal (group) {
      try {
        const response = await getContactsForGroup({ groupId: group.id, params: { page_size: 1 } })
        this.selectedGroup = { ...group, count: response?.data?.count }
        this.$refs.assignTagModal.show()
      } catch (error) {
        console.error(error)
        errorModal('Tags kunnen niet worden toegekend')
      }
    },
    async fetchGroups (payload) {
      try {
        this.loading = true
        const response = await getContactGroups(payload)
        this.groupData = response.data
        return response
      } catch (error) {
        errorModal('Fout bij het laden van groepen')
        throw error
      } finally {
        this.loading = false
      }
    },
    async searchGroups (data) {
      try {
        const params = { ordering: 'name', ...data }

        if (params.having_office) params.having_office = params.having_office.id
        if (params.having_collaborator) params.having_collaborator = params.having_collaborator.id
        if (this.adminView) params.fetch_all = 1

        return await this.fetchGroups({ params })
      } catch (error) {
        this.$formulate.handle(error, 'searchGroups')
      }
    },
    showEditModal (name, object) {
      this.activeEditConfig = contactsGroupForms(name, this.isStaff)
      this.activeObject = object
      this.$refs.editModal.show()
    },
    async handleGroupDelete (groupId) {
      try {
        const result = await questionModal('Weet je zeker dat je groep wil verwijderen? De contacten in deze groep zullen NIET worden verwijderd.')
        if (!result.value) return false

        startLoadingModal('Deze groep wordt verwijderd...')
        const response = await deleteContactsGroup(groupId)
        await this.searchGroups()
        successModal('Deze groep is verwijderd')
        return response
      } catch {
        errorModal('Kan groep niet verwijderen gelieve opnieuw te proberen')
      }
    },
    async handleGroupSave (groupData) {
      try {
        groupData.owner = !groupData.owner ? this.collaborator.id : groupData.owner.id
        const keys = ['confidants', 'offices', 'relations']
        keys.forEach((key) => {
          if (groupData[key]) {
            groupData[key] = groupData[key].map((keyItem) => keyItem.id)
          }
        })
        await createContactsGroup(groupData)
        await this.searchGroups()
        successModal('De groep is succesvol aangemaakt')
      } catch {
        errorModal('Kan groep niet aanmaken, gelieve opnieuw te proberen.')
      }
    },
    async handleGroupEdit (groupData) {
      try {
        const keys = ['confidants', 'offices']
        keys.forEach((key) => {
          if (groupData[key]) {
            groupData[key] = groupData[key].map((keyItem) => keyItem.id)
          }
        })
        groupData.id = this.activeObject.id
        await updateContactsGroup(groupData)
        await this.searchGroups()
        successModal('De wijzigingen aan de groep zijn opgeslaan')
      } catch (error) {
        let errorMessage = 'Kan groep niet wijzigen gelieve opnieuw te proberen.'
        if (error.response?.data?.parent?.code === 'GROUP_CIRCULAR_REFERENCE') {
          errorMessage = 'Groep kan niet als eigen ouder worden ingesteld'
        }
        errorModal(errorMessage)
      }
    },
    async handleGroup (group) {
      startLoadingModal('Wijzigingen worden doorgevoerd.')
      const groupData = { ...group }
      const isNewContactGroup = !Object.keys(this.activeObject).length
      if (groupData.parent) groupData.parent = groupData.parent.id || ''

      if (isNewContactGroup) await this.handleGroupSave(groupData)
      else await this.handleGroupEdit(groupData)

      this.$refs.editModal.hide()
    },
    async handleGroupExport (group_id) {
      try {
        startLoadingModal('Bezig met exporteren van contacten.')
        const response = await exportContactGroup({ group_id })
        const jobId = response.data.job_id
        await this.pollContactExportStatus(jobId)
        successModal('De contacten zijn geexporteerd.')
        return response
      } catch (e) {
        errorModal('Kan contacten niet exporteren, gelieve opnieuw te proberen')
      }
    }
  }
}
</script>
